import localFont from 'next/font/local';

const font = localFont({
  src: [
    {
      path: './DB-Heavent.ttf',
      weight: '400',
      style: 'normal',
    },
    {
      path: './DB-Heavent-Med.ttf',
      weight: '500',
      style: 'normal',
    },
    {
      path: './DB-Heavent-Bd.ttf',
      weight: '700',
      style: 'normal',
    },
    {
      path: './DB-Heavent-Blk.ttf',
      weight: '900',
      style: 'normal',
    },
  ],
});

export default font;
